const advancedInputWeights = {
	partingPlaneWeightage: 0.1,
	contourPlaneWeightage: 0.15,
	pullCoreWeightage: 0.2,
	solubleCoreWeightage: 0.35,
	externalCoreWeightage: 0.2,
	internalCoreWeightage: 0.35,
};

export default advancedInputWeights;

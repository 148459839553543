import React, { useState, useEffect } from "react";
import { Loading } from "../../components/Loading/Loading";
import {
  ErrorMessage,
  SuccessMessage,
  WarningMessage,
} from "../../components/Messages/messages";
import axios from "axios";
import { isAuthenticated } from "../../components/Auth/auth";
import "./Home.css";
// import advancedComplexityTable from './advancedComplexityTable.js';
import advancedInputWeights from "./advancedInputWeights.js";
import partDensities from "./partDensities.js";
import { useLocation } from "react-router-dom";

export const Home = (props) => {
  // console.log("Home Component Props:", props);

  useEffect(() => {
    // Client-side check for authentication
    if (!isAuthenticated()) {
      WarningMessage("You must be logged in to access this page.");
      props.history.push("/login"); // Redirect to login
    }
  }, [props.history]);

  const location = useLocation();

  // Parse the query string
  const queryParams = new URLSearchParams(location.search);

  // Get the 'id' parameter
  const id = queryParams.get("id");

  const [loading, setLoading] = useState(false);
  const [showAdvancedFields, setAdvancedFieldsVisibility] = useState(false);
  const [showGeneralFields, setGeneralFieldsVisibility] = useState(false);
  const [selectedImageKey, setImageKey] = useState(false);
  const [imageComplexityMap] = useState([
    3.4, 3.6, 3.8, 3.8, 3.4, 3.6, 3.6, 3.8, 1.2, 2.8, 3.8, 1, 1, 1, 1, 1.2, 1,
    5, 1.2, 5.4, 1, 2.2, 2.2, 2.2, 1.2, 1.2, 1.2, 1.4, 1.2, 3.4, 2.6, 4.6,
  ]);
  const [selectedComplexity, setComplexity] = useState("1");
  const [selectedUserType, setUserType] = useState("");
  const [selectedOption, setSelectedOption] = useState("weight");
  const [castingMethod, setCastingMethod] = useState("investment");

  const [data, setData] = useState({
    userType: "",
    castingType: "investment",
    partNumber: "",
    partMaterial: "A-356",
    grade: "A",
    partClass: "1",
    partQuantity: "",
    length: "",
    width: "",
    height: "",
    partDetail: "weight",
    partWeight: "",
    partVolume: "",
    surfaceArea: "",
    partComplexity: "",
    partEnvelopeVolume: "",
    numPartingLines: "",
    numContourLines: "",
    numPullCores: "",
    numSolubleCores: "",
    numInternalCores: "",
    numExternalCores: "",
    pullCoreVolumes: [],
    solubleCoreVolumes: [],
    internalCoreVolumes: [],
    externalCoreVolumes: [],
  });

  const {
    userType,
    castingType,
    partNumber,
    partMaterial,
    grade,
    partClass,
    partQuantity,
    length,
    width,
    height,
    partDetail,
    partWeight,
    partVolume,
    surfaceArea,
    partComplexity,
    partEnvelopeVolume,
    numPartingLines,
    numContourLines,
    numPullCores,
    numSolubleCores,
    numInternalCores,
    numExternalCores,
    pullCoreVolumes,
    solubleCoreVolumes,
    internalCoreVolumes,
    externalCoreVolumes,
  } = data;

  const roundTo2Decimals = (num) => {
    return +(Math.round(num + "e+2")  + "e-2");
  };

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleAdvancedUserButtonClick = () => {
    setGeneralFieldsVisibility(false);
    setAdvancedFieldsVisibility(!showAdvancedFields);
  };

  const handleGeneralUserButtonClick = () => {
    setAdvancedFieldsVisibility(false);
    setGeneralFieldsVisibility(!showGeneralFields);
  };

  const handleImageSelection = (index) => {
    setUserType("General");
    // setData({ ...data, userType: 'General' });
    if (index === selectedImageKey) {
      // Deselecting the image, set selectedImageKey to null and complexity to 1
      setImageKey(null);
      setComplexity("1");
      setData({ ...data, partComplexity: "1" });
    } else {
      // Selecting a new image, update selectedImageKey and complexity
      setImageKey(index);
      // Check if the complexity map is defined for the selected image
      if (imageComplexityMap[index] !== undefined) {
        setComplexity(imageComplexityMap[index].toString());
        setData({
          ...data,
          partComplexity: imageComplexityMap[index].toString(),
        });
      } else {
        // If not defined, set the complexity to '1'
        setComplexity("1");
        setData({ ...data, partComplexity: "1" });
      }
    }
  };

  const handleComplexityUpdate = () => {
    setUserType("Advanced");
    
    const castingMethod = data.castingType;
    // console.log("Casting Method: ", data.castingType);
    const inputPartingPlanes = isNaN(parseInt(data.numPartingLines)) ? 0 : parseInt(data.numPartingLines);
    const inputContourPlanes = isNaN(parseInt(data.numContourLines)) ? 0 : parseInt(data.numContourLines);

    if(castingMethod === "investment") {
      const inputPullCores = isNaN(parseInt(data.numPullCores)) ? 0 : parseInt(data.numPullCores);
      const inputSolubleCores = isNaN(parseInt(data.numSolubleCores)) ? 0 : parseInt(data.numSolubleCores);

      if (inputPartingPlanes === 0 && inputContourPlanes === 0 && inputPullCores === 0 && inputSolubleCores === 0) {
        WarningMessage("You must specify number of parting lines to update complexity.");
        return;
      }

      var complexity = 1;
      if (inputPartingPlanes === 1 && inputContourPlanes === 0 && inputPullCores === 0 && inputSolubleCores === 0) {
        setComplexity("1");
        setData({ ...data, partComplexity: "1" });
      } else {
        complexity += inputPartingPlanes * advancedInputWeights.partingPlaneWeightage;
        complexity += inputContourPlanes * advancedInputWeights.contourPlaneWeightage;
        complexity += inputPullCores * advancedInputWeights.pullCoreWeightage;
        complexity += inputSolubleCores * advancedInputWeights.solubleCoreWeightage;
        complexity = Math.round((complexity + Number.EPSILON) * 100) / 100;
        setComplexity(complexity);
        setData({ ...data, partComplexity: complexity });
      }
      SuccessMessage("Successfully updated part complexity.");

      setShowPullCoreInput(true);
      // Parse the value from the input field to determine the number of pull cores entered
      const numEntered = parseInt(data.numPullCores, 10);
      if (!isNaN(numEntered) && numEntered >= 0) {
        setNumPullCoresEntered(numEntered);
      }
      /*
      else {
        // Handle invalid input
        alert("Please enter a valid number of pull cores.");
      }
      */

      setShowSolubleCoreInput(true);
      // Parse the value from the input field to determine the number of soluble cores entered
      const numScEntered = parseInt(data.numSolubleCores, 10);
      if (!isNaN(numScEntered) && numScEntered >= 0) {
        setNumSolubleCoresEntered(numScEntered);
      }
      /*
      else {
        // Handle invalid input
        alert("Please enter a valid number of soluble cores.");
      }
      */
    } else {
      const inputInternalCores = isNaN(parseInt(data.numInternalCores)) ? 0 : parseInt(data.numInternalCores);
      const inputExternalCores = isNaN(parseInt(data.numExternalCores)) ? 0 : parseInt(data.numExternalCores);

      if (inputPartingPlanes === 0 && inputContourPlanes === 0 && inputInternalCores === 0 && inputExternalCores === 0) {
        WarningMessage("You must specify number of parting lines to update complexity.");
        return;
      }

      var complexity = 1;
      if (inputPartingPlanes === 1 && inputContourPlanes === 0 && inputInternalCores === 0 && inputExternalCores === 0) {
        setComplexity("1");
        setData({ ...data, partComplexity: "1" });
      } else {
        complexity += inputPartingPlanes * advancedInputWeights.partingPlaneWeightage;
        complexity += inputContourPlanes * advancedInputWeights.contourPlaneWeightage;
        complexity += inputInternalCores * advancedInputWeights.internalCoreWeightage;
        complexity += inputExternalCores * advancedInputWeights.externalCoreWeightage;
        complexity = Math.round((complexity + Number.EPSILON) * 100) / 100;
        setComplexity(complexity);
        setData({ ...data, partComplexity: complexity });
      }
      SuccessMessage("Successfully updated part complexity.");

      setShowInternalCoreInput(true);
      // Parse the value from the input field to determine the number of internal cores entered
      const numEntered = parseInt(data.numInternalCores, 10);
      if (!isNaN(numEntered) && numEntered >= 0) {
        setNumInternalCoresEntered(numEntered);
      }

      setShowExternalCoreInput(true);
      // Parse the value from the input field to determine the number of external cores entered
      const numEnteredExternal = parseInt(data.numExternalCores, 10);
      if (!isNaN(numEnteredExternal) && numEnteredExternal >= 0) {
        setNumExternalCoresEntered(numEnteredExternal);
      }
    }
    
  };

  useEffect(() => {
    setData({
      ...data,
      userType: selectedUserType,
      castingType: castingMethod,
    });
  }, [selectedUserType, castingMethod]);

  const getCalculationById = async () => {
    await axios
      .get(
        `https://api.castingcostadvisor.com/api/calculations/get/${isAuthenticated()._id}`,
        // `http://localhost:8000/api/calculations/get/${isAuthenticated()._id}`,
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          let pData = res.data?.find((el) => el?._id == id);

          const roundedData = {
            ...pData,
            partWeight: roundTo2Decimals(pData?.partWeight),
            partVolume: roundTo2Decimals(pData?.partVolume),
          };

          // Merge pData with existing state to ensure defaults are preserved
          setData((prevData) => ({
            ...prevData,
            ...roundedData,
          }));

          setComplexity(roundedData?.partComplexity);

          // console.log("Updated Data --> ", {
          //   ...data,
          //   ...roundedData,
          // });

        } else {
          ErrorMessage(res.data.errorMessage);
        }
      });
  };

  useEffect(() => {
    if (id) {
      getCalculationById();
    }
  }, [id]);

  const [numPullCoresEntered, setNumPullCoresEntered] = useState(null); // Number of pull cores entered
  const [pullCoreBoxVolume, setPullCoreBoxVolume] = useState(""); // Input field for pull core box volume
  const [enteredPullCoreVolumes, setPullCoreVolumes] = useState([]); // Array to store entered pull core volumes
  const [showPullCoreInput, setShowPullCoreInput] = useState(false);

  const [numSolubleCoresEntered, setNumSolubleCoresEntered] = useState(null); // Number of soluble cores entered
  const [solubleCoreBoxVolume, setSolubleCoreBoxVolume] = useState(""); // Input field for soluble core box volume
  const [enteredSolubleCoreVolumes, setSolubleCoreVolumes] = useState([]); // Array to store entered soluble core volumes
  const [showSolubleCoreInput, setShowSolubleCoreInput] = useState(false);

  const [numInternalCoresEntered, setNumInternalCoresEntered] = useState(null); // Number of internal cores entered
  const [internalCoreLength, setInternalCoreLength] = useState("");
  const [internalCoreWidth, setInternalCoreWidth] = useState("");
  const [internalCoreHeight, setInternalCoreHeight] = useState("");
  const [internalCoreBoxVolume, setInternalCoreBoxVolume] = useState(""); // Input field for internal core box volume
  const [enteredInternalCoreVolumes, setInternalCoreVolumes] = useState([]); // Array to store entered internal core volumes
  const [showInternalCoreInput, setShowInternalCoreInput] = useState(false);

  const [numExternalCoresEntered, setNumExternalCoresEntered] = useState(null); // Number of external cores entered
  const [externalCoreLength, setExternalCoreLength] = useState("");
  const [externalCoreWidth, setExternalCoreWidth] = useState("");
  const [externalCoreHeight, setExternalCoreHeight] = useState("");
  const [externalCoreBoxVolume, setExternalCoreBoxVolume] = useState(""); // Input field for external core box volume
  const [enteredExternalCoreVolumes, setExternalCoreVolumes] = useState([]); // Array to store entered external core volumes
  const [showExternalCoreInput, setShowExternalCoreInput] = useState(false);

  const handleAddPullCoreBoxVolume = () => {
    if (pullCoreBoxVolume.trim() !== "") {
      setPullCoreVolumes([...enteredPullCoreVolumes, pullCoreBoxVolume]);
      setData({
        ...data,
        pullCoreVolumes: [...enteredPullCoreVolumes, pullCoreBoxVolume],
      });
      setPullCoreBoxVolume(""); // Clear the input field
    } else {
      // Handle empty input
      WarningMessage("Please enter a pull core box volume.");
    }
  };

  const handleRemovePullCore = () => {
    if (enteredPullCoreVolumes.length > 0) {
      const updatedPullCoreVolumes = [...enteredPullCoreVolumes];
      updatedPullCoreVolumes.pop(); // Remove the last entry
      setPullCoreVolumes(updatedPullCoreVolumes);
      setData({ ...data, pullCoreVolumes: updatedPullCoreVolumes });
    }
  };

  const handleAddSolubleCoreBoxVolume = () => {
    if (solubleCoreBoxVolume.trim() !== "") {
      setSolubleCoreVolumes([
        ...enteredSolubleCoreVolumes,
        solubleCoreBoxVolume,
      ]);
      setData({
        ...data,
        solubleCoreVolumes: [
          ...enteredSolubleCoreVolumes,
          solubleCoreBoxVolume,
        ],
      });
      setSolubleCoreBoxVolume(""); // Clear the input field
    } else {
      // Handle empty input
      WarningMessage("Please enter a soluble core box volume.");
    }
  };

  const handleRemoveSolubleCore = () => {
    if (enteredSolubleCoreVolumes.length > 0) {
      const updatedSolubleCoreVolumes = [...enteredSolubleCoreVolumes];
      updatedSolubleCoreVolumes.pop(); // Remove the last entry
      setSolubleCoreVolumes(updatedSolubleCoreVolumes);
      setData({ ...data, solubleCoreVolumes: updatedSolubleCoreVolumes });
    }
  };

  const handleAddInternalCoreBoxVolume = () => {
    if (internalCoreLength.trim() !== "" && internalCoreWidth.trim() !== "" && internalCoreHeight.trim() !== "") {
      // const volume = internalCoreLength * internalCoreWidth * internalCoreHeight;
      const volume = parseFloat(internalCoreLength) * parseFloat(internalCoreWidth) * parseFloat(internalCoreHeight);
      setInternalCoreVolumes([...enteredInternalCoreVolumes, volume]);
      setData({
        ...data,
        internalCoreVolumes: [...enteredInternalCoreVolumes, volume],
      });
      setInternalCoreLength("");
      setInternalCoreWidth("");
      setInternalCoreHeight("");
    } else {
      WarningMessage("Please enter all dimensions for the internal core.");
    }
  };

  const handleRemoveInternalCore = () => {
    if (enteredInternalCoreVolumes.length > 0) {
      const updatedInternalCoreVolumes = [...enteredInternalCoreVolumes];
      updatedInternalCoreVolumes.pop(); // Remove the last entry
      setInternalCoreVolumes(updatedInternalCoreVolumes);
      setData({ ...data, internalCoreVolumes: updatedInternalCoreVolumes });
    }
  };

  const handleAddExternalCoreBoxVolume = () => {
    if (externalCoreLength.trim() !== "" && externalCoreWidth.trim() !== "" && externalCoreHeight.trim() !== "") {
      // const volume = externalCoreLength * externalCoreWidth * externalCoreHeight;
      const volume = parseFloat(externalCoreLength) * parseFloat(externalCoreWidth) * parseFloat(externalCoreHeight);
      setExternalCoreVolumes([...enteredExternalCoreVolumes, volume]);
      setData({
        ...data,
        externalCoreVolumes: [...enteredExternalCoreVolumes, volume],
      });
      setExternalCoreLength("");
      setExternalCoreWidth("");
      setExternalCoreHeight("");
    } else {
      WarningMessage("Please enter all dimensions for the external core.");
    }
  };

  const handleRemoveExternalCore = () => {
    if (enteredExternalCoreVolumes.length > 0) {
      const updatedExternalCoreVolumes = [...enteredExternalCoreVolumes];
      updatedExternalCoreVolumes.pop(); // Remove the last entry
      setExternalCoreVolumes(updatedExternalCoreVolumes);
      setData({ ...data, externalCoreVolumes: updatedExternalCoreVolumes });
    }
  };

  const titleText =
    castingMethod === "investment"
      ? "COST ADVISOR FOR INVESTMENT CASTINGS"
      : "COST ADVISOR FOR SAND CASTINGS";

  // handle casting type change
  const handleCastingMethodChange = (type) => {
    setCastingMethod(type);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (isAuthenticated()) {
      setLoading(true);

      // console.log("pullCoreVolumes:", data.pullCoreVolumes);
      // console.log("solubleCoreVolumes:", data.solubleCoreVolumes);

      var partDensity = partDensities[data.partMaterial];
      // console.log("Part density -> ", partDensity);

      let partBoxVolume = data.length * data.width * data.height;
      // let metalDensity = 0.101;
      // let partVolume = partWeight / metalDensity;
      let partVolume = partWeight / partDensity;
      // console.log("Part box volume -> ", partBoxVolume);
      // console.log("Part volume -> ", partVolume);
      // console.log("Part envelope volume -> ", partEnvelopeVolume);

      if (partBoxVolume <= partEnvelopeVolume) {
        WarningMessage(
          "Invalid entries. REQUIRED: Part envelope volume must be less than box volume."
        );
        setLoading(false);
      } else if (partBoxVolume <= partVolume) {
        WarningMessage(
          "Invalid entries. REQUIRED: Part volume must be less than box volume."
        );
        setLoading(false);
      } else if (partEnvelopeVolume <= partVolume) {
        WarningMessage(
          "Invalid entries. REQUIRED: Part volume must be less than part envelope volume."
        );
        setLoading(false);
      } else {
        axios
          .post(
            // "http://localhost:8000/api/calculations/create",
            "https://api.castingcostadvisor.com/api/calculations/create",
            {
              userType,
              castingType,
              partNumber,
              partMaterial,
              grade,
              partClass,
              partQuantity,
              length,
              width,
              height,
              partDetail,
              partWeight,
              partVolume,
              surfaceArea,
              partComplexity,
              partEnvelopeVolume,
              numPartingLines,
              numContourLines,
              numPullCores,
              numSolubleCores,
              numInternalCores,
              numExternalCores,
              pullCoreVolumes,
              solubleCoreVolumes,
              internalCoreVolumes,
              externalCoreVolumes,
            },
            {
              headers: {
                authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            if (res.status === 200) {
              setLoading(false);
              SuccessMessage(res.data.successMessage);
              props.history.push("/results");
            } else {
              ErrorMessage(res.data.errorMessage);
            }
          });
      }
    } else {
      WarningMessage(
        "Please log in to run cost calculations & save data to the backend."
      );
      props.history.push("/login");
    }
  };

  const imageFilenames = [
    "image1.png",
    "image2.png",
    "image3.png",
    "image4.png",
    "image5.png",
    "image6.png",
    "image7.png",
    "image8.png",
    "image9.png",
    "image10.png",
    "image11.png",
    "image12.png",
    "image13.png",
    "image14.png",
    "image15.png",
    "image16.png",
    "image17.png",
    "image18.png",
    "image19.png",
    "image20.png",
    "image21.png",
    "image22.png",
    "image23.png",
    "image24.png",
    "image25.png",
    "image26.png",
    "image27.png",
    "image28.png",
    "image29.png",
    "image30.png",
    "image31.png",
    "image32.png",
  ];

  return (
    <div className="home">
      <form onSubmit={submitHandler}>
        <h4 className="display-3 text-center text-primary title">
          <b>{titleText}</b>
        </h4>
        <br/>
        <div className="casting-toggle-buttons">
          <button type="button" className={`btn ${castingMethod === "investment" ? "selected" : "unselected"}`} onClick={() => handleCastingMethodChange("investment")}>
            Investment Castings
          </button>
          <button type="button" className={`btn ${castingMethod === "sand" ? "selected" : "unselected"}`} onClick={() => handleCastingMethodChange("sand")}>
            Sand Castings
          </button>
        </div>
        <br/>
        <div className="form-floating">
          <input name="partNumber" type="text" className="form-control" required onChange={handleChange} id="floatingPassword" placeholder="partNumber" value={partNumber}/>
          <label for="floatingPassword">Part Number / Name</label>
        </div>
        <br/>
        <div>
          <h5>Materials Section:</h5>
          <label className="label-styles" for="partMaterial">
            Part Material
          </label>
          <select name="partMaterial" className="form-control" required id="partMaterial" onChange={handleChange} placeholder="Part Material" value={partMaterial}>
            <option value="A-356" selected>{" "}A-356{" "}</option>
            <option value="A-357"> A-357 </option>
            <option value="A-148"> A-148 </option>
          </select>
          <br/>
          <label className="label-styles" for="grade">
            Grade
          </label>
          <select name="grade" className="form-control" required id="grade" onChange={handleChange} placeholder="Grade" value={grade}>
            <option value="A" selected>{" "}A{" "}</option>
            <option value="B"> B </option>
            <option value="C"> C </option>
            <option value="D"> D </option>
          </select>
          <br/>
          <label className="label-styles" for="class">
            Class
          </label>
          <select name="partClass" className="form-control" required id="class" onChange={handleChange} placeholder="Class" value={partClass}>
            <option value="1" selected>{" "}1{" "}</option>
            <option value="2"> 2 </option>
            <option value="3"> 3 </option>
            <option value="4"> 4 </option>
          </select>
          <br />

          <div className="row mt-4">
            <h5>Enter part complexity:</h5>
          </div>

          <div className="form-floating">
            <input name="partComplexity" type="text" className="form-control" id="partComplexity" value={selectedComplexity} placeholder="partComplexity" required disabled/>
            <label for="partComplexity">Part Complexity</label>
          </div>

          <div className="row my-3">
            <div className="col-sm">
              <button type="button" onClick={handleGeneralUserButtonClick} className="btn btn-primary w-100" data-toggle="modal" data-target="#exampleModal">
                General User
              </button>
            </div>
            <div className="col-sm align-self-center">
              <button type="button" onClick={handleAdvancedUserButtonClick} className="btn btn-primary w-100">
                Advanced User
              </button>
            </div>
            <div className="col-sm">
              <a href="/assets/aluminum-part-complexity-lib.pdf" target="_blank" rel="noreferrer">
                <button type="button" className="btn btn-primary w-100">
                  More examples
                </button>
              </a>
            </div>
          </div>
          <br/>

          <input name="userType" type="hidden" className="form-control" id="userType" value={selectedUserType}/>

          <div style={{ display: showAdvancedFields ? "block" : "none" }}>
            <div className="form-floating mb-3">
              <input name="numPartingLines" type="text" className="form-control" onChange={handleChange} id="partingLines" placeholder="No. of parting lines" value={numPartingLines}/>
              <label for="partingLines">No. of parting lines</label>
            </div>
            <div className="form-floating mb-3">
              <input name="numContourLines" type="text" className="form-control" onChange={handleChange} id="contourLines" placeholder="No. of contour lines" value={numContourLines}/>
              <label for="contourLines">No. of contour lines</label>
            </div>

            {castingMethod === "investment" && (
            <>
              <div className="form-floating mb-3">
                <input name="numPullCores" type="text" className="form-control" onChange={handleChange} id="pullCores" placeholder="No. of pull cores"  value={numPullCores}/>
                <label for="pullCores">No. of pull cores</label>
              </div>
              <div className="form-floating mb-3">
                <input name="numSolubleCores" type="text" className="form-control" onChange={handleChange} id="solubleCores" placeholder="No. of soluble cores" value={numSolubleCores}/>
                <label for="solubleCores">No. of soluble cores</label>
              </div>
            </>
            )}

            {castingMethod === "sand" && (
            <>
              <div className="form-floating mb-3">
                <input name="numInternalCores" type="text" className="form-control" onChange={handleChange} id="internalCores" placeholder="No. of internal cores"  value={numInternalCores}/>
                <label for="internalCores">No. of internal cores</label>
              </div>
              <div className="form-floating mb-3">
                <input name="numExternalCores" type="text" className="form-control" onChange={handleChange} id="externalCores" placeholder="No. of external cores" value={numExternalCores}/>
                <label for="externalCores">No. of external cores</label>
              </div>
            </>
            )}
            
            <div className="form-floating mb-5">
              <button type="button" id="updateComplexity" className="btn btn-primary w-100" onClick={handleComplexityUpdate}>
                Update complexity
              </button>
            </div>

            <div>
              {castingMethod === "investment" && numPullCoresEntered !== null && numPullCoresEntered > 0 && (
                <div>
                  <h3>Enter Pull Core Box Volumes</h3>
                  <div className="form-floating mb-3">
                    <input type="text" name="pullCoreBoxVolume" id="pullCoreBoxVolume" className="form-control" placeholder="Pull core box volume" value={pullCoreBoxVolume} onChange={(e) => setPullCoreBoxVolume(e.target.value)} disabled={enteredPullCoreVolumes.length >= numPullCoresEntered}/>
                    <label for="pullCoreBoxVolume">Pull Core Box Volume</label>
                  </div>
                  <div className="form-floating mb-5">
                    <button type="button" id="addPullCoreBoxVolume" onClick={handleAddPullCoreBoxVolume} className="btn btn-primary w-100" disabled={enteredPullCoreVolumes.length >= numPullCoresEntered}>
                      Add Pull Core Box Volume
                    </button>
                  </div>
                  <div className="mb-3">
                    <h3>Pull Core Box Volumes</h3>
                    {enteredPullCoreVolumes.length > 0 ? (
                      <div className="row row-cols-1 row-cols-md-3">
                        {enteredPullCoreVolumes.map((volume, index) => (
                          <div className="col mb-3" key={index}>
                            <div className="card">
                              <div className="card-body">
                                <div className="row">
                                  <div className="col">
                                    <p className="card-text">
                                      <b>Pull Core Volume {index + 1}:</b>{" "}{volume}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="text-white">
                        No pull core box volumes specified.
                      </p>
                    )}
                    <div className="form-floating mb-5">
                      <button type="button" className="btn btn-danger w-100" onClick={handleRemovePullCore} disabled={enteredPullCoreVolumes.length === 0}>
                        Remove Most Recent Entry
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div>
              {castingMethod === "investment" && numSolubleCoresEntered !== null && numSolubleCoresEntered > 0 && (
                <div>
                  <h3>Enter Soluble Core Box Volumes</h3>
                  <div className="form-floating mb-3">
                    <input type="text" name="solubleCoreBoxVolume" id="solubleCoreBoxVolume" className="form-control" placeholder="Soluble core box volume" value={solubleCoreBoxVolume} onChange={(e) => setSolubleCoreBoxVolume(e.target.value)} disabled={enteredSolubleCoreVolumes.length >= numSolubleCoresEntered}/>
                    <label for="solubleCoreBoxVolume">
                      Soluble Core Box Volume
                    </label>
                  </div>
                  <div className="form-floating mb-5">
                    <button type="button" id="addSolubleCoreBoxVolume" onClick={handleAddSolubleCoreBoxVolume} className="btn btn-primary w-100" disabled={enteredSolubleCoreVolumes.length >= numSolubleCoresEntered}>
                      Add Soluble Core Box Volume
                    </button>
                  </div>
                  <div className="mb-3">
                    <h3>Soluble Core Box Volumes</h3>
                    {enteredSolubleCoreVolumes.length > 0 ? (
                      <div className="row row-cols-1 row-cols-md-3">
                        {enteredSolubleCoreVolumes.map((volume, index) => (
                          <div className="col mb-3" key={index}>
                            <div className="card">
                              <div className="card-body">
                                <div className="row">
                                  <div className="col">
                                    <p className="card-text">
                                      <b>Soluble Core Volume {index + 1}:</b>{" "}
                                      {volume}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="text-white">
                        No soluble core box volumes specified.
                      </p>
                    )}
                    <div className="form-floating mb-5">
                      <button type="button" className="btn btn-danger w-100" onClick={handleRemoveSolubleCore} disabled={enteredSolubleCoreVolumes.length === 0}>
                        Remove Most Recent Entry
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div>
              {castingMethod === "sand" && numInternalCoresEntered !== null && numInternalCoresEntered > 0 && (
                <div>
                  <h3>Enter Internal Core Box Volumes</h3>
                  <div className="form-floating mb-3">
                    <input type="text" name="internalCoreLength" id="internalCoreLength" className="form-control" placeholder="Internal core length" value={internalCoreLength} onChange={(e) => setInternalCoreLength(e.target.value)} disabled={enteredInternalCoreVolumes.length >= numInternalCoresEntered}/>
                    <label for="internalCoreLength">Internal Core Length</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input type="text" name="internalCoreWidth" id="internalCoreWidth" className="form-control" placeholder="Internal core width" value={internalCoreWidth} onChange={(e) => setInternalCoreWidth(e.target.value)} disabled={enteredInternalCoreVolumes.length >= numInternalCoresEntered}/>
                    <label for="internalCoreWidth">Internal Core Width</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input type="text" name="internalCoreHeight" id="internalCoreHeight" className="form-control" placeholder="Internal core height" value={internalCoreHeight} onChange={(e) => setInternalCoreHeight(e.target.value)} disabled={enteredInternalCoreVolumes.length >= numInternalCoresEntered}/>
                    <label for="internalCoreHeight">Internal Core Height</label>
                  </div>
                  <div className="form-floating mb-5">
                    <button type="button" id="addInternalCoreBoxVolume" onClick={handleAddInternalCoreBoxVolume} className="btn btn-primary w-100" disabled={enteredInternalCoreVolumes.length >= numInternalCoresEntered}>
                      Add Internal Core Box Volume
                    </button>
                  </div>
                  <div className="mb-3">
                    <h3>Internal Core Box Volumes</h3>
                    {enteredInternalCoreVolumes.length > 0 ? (
                      <div className="row row-cols-1 row-cols-md-3">
                        {enteredInternalCoreVolumes.map((volume, index) => (
                          <div className="col mb-3" key={index}>
                            <div className="card">
                              <div className="card-body">
                                <div className="row">
                                  <div className="col">
                                    <p className="card-text">
                                      <b>Internal Core Volume {index + 1}:</b>{" "}
                                      {volume}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="text-white">
                        No internal core box volumes specified.
                      </p>
                    )}
                    <div className="form-floating mb-5">
                      <button type="button" className="btn btn-danger w-100" onClick={handleRemoveInternalCore} disabled={enteredInternalCoreVolumes.length === 0}>
                        Remove Most Recent Entry
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div>
              {castingMethod === "sand" && numExternalCoresEntered !== null && numExternalCoresEntered > 0 && (
                <div>
                  <h3>Enter External Core Box Volumes</h3>
                  <div className="form-floating mb-3">
                    <input type="text" name="externalCoreLength" id="externalCoreLength" className="form-control" placeholder="External core length" value={externalCoreLength} onChange={(e) => setExternalCoreLength(e.target.value)} disabled={enteredExternalCoreVolumes.length >= numExternalCoresEntered}/>
                    <label for="externalCoreLength">External Core Length</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input type="text" name="externalCoreWidth" id="externalCoreWidth" className="form-control" placeholder="External core width" value={externalCoreWidth} onChange={(e) => setExternalCoreWidth(e.target.value)} disabled={enteredExternalCoreVolumes.length >= numExternalCoresEntered}/>
                    <label for="externalCoreWidth">External Core Width</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input type="text" name="externalCoreHeight" id="externalCoreHeight" className="form-control" placeholder="External core height" value={externalCoreHeight} onChange={(e) => setExternalCoreHeight(e.target.value)} disabled={enteredExternalCoreVolumes.length >= numExternalCoresEntered}/>
                    <label for="externalCoreHeight">External Core Height</label>
                  </div>
                  <div className="form-floating mb-5">
                    <button type="button" id="addExternalCoreBoxVolume" onClick={handleAddExternalCoreBoxVolume} className="btn btn-primary w-100" disabled={enteredExternalCoreVolumes.length >= numExternalCoresEntered}>
                      Add External Core Box Volume
                    </button>
                  </div>
                  <div className="mb-3">
                    <h3>External Core Box Volumes</h3>
                    {enteredExternalCoreVolumes.length > 0 ? (
                      <div className="row row-cols-1 row-cols-md-3">
                        {enteredExternalCoreVolumes.map((volume, index) => (
                          <div className="col mb-3" key={index}>
                            <div className="card">
                              <div className="card-body">
                                <div className="row">
                                  <div className="col">
                                    <p className="card-text">
                                      <b>External Core Volume {index + 1}:</b>{" "}
                                      {volume}
                                    </p>
                                  </div>
                                  </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="text-white">
                        No external core box volumes specified.
                      </p>
                    )}
                    <div className="form-floating mb-5">
                      <button type="button" className="btn btn-danger w-100" onClick={handleRemoveExternalCore} disabled={enteredExternalCoreVolumes.length === 0}>
                        Remove Most Recent Entry
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <input name="pullCoreVolumes" type="hidden" className="form-control" id="pullCoreVolumes" value={enteredPullCoreVolumes}/>
            <input name="solubleCoreVolumes" type="hidden" className="form-control" id="solubleCoreVolumes" value={enteredSolubleCoreVolumes}/>
            <input name="internalCoreVolumes" type="hidden" className="form-control" id="internalCoreVolumes" value={enteredInternalCoreVolumes}/>
            <input name="externalCoreVolumes" type="hidden" className="form-control" id="externalCoreVolumes" value={enteredExternalCoreVolumes}/>
          </div>

          <div style={{ display: showGeneralFields ? "block" : "none" }}>
            <div className="my-container mb-3">
              <div className="images-wrapper mb-2">
                {imageFilenames.map((filename, index) => (
                  <div key={index} id={`part-img-${index + 1}`} className={`image-item ${selectedImageKey === index ? "highlighted" : ""}`} onClick={() => handleImageSelection(index)}>
                    <img src={`assets/part-images/${filename}`} alt={`${filename}`}/>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="form-floating">
            <input name="partQuantity" type="text" className="form-control" required onChange={handleChange} id="partQuant" placeholder="Part Quantity" value={partQuantity}/>
            <label for="partQuant">Part Quantity</label>
          </div>
        </div>
        <br />

        <div>
          <div className="row my-3 mt-4">
            <div className="col-md-6">
              <h5>Enter box dimensions:</h5>
            </div>
            <div className="col-md-6 mb-3 align-self-center">
              <a href="/assets/box-dims.pdf" target="_blank" rel="noreferrer">
                <button type="button" className="btn btn-primary w-100">
                  How to enter Box Dimensions
                </button>
              </a>
            </div>
          </div>
          <div className="form-floating mb-3">
            <input name="length" type="text" className="form-control" required onChange={handleChange} id="partLen" placeholder="Length (inches)" value={length}/>
            <label for="partLen">Length (inches)</label>
          </div>
          <div className="form-floating mb-3">
            <input name="width" type="text" className="form-control" required onChange={handleChange} id="partWid" placeholder="Width (inches)" value={width}/>
            <label for="partWid">Width (inches)</label>
          </div>
          <div className="form-floating mb-5">
            <input name="height" type="text" className="form-control" required onChange={handleChange} id="partHeight" placeholder="Height (inches)" value={height}/>
            <label for="partHeight">Height (inches)</label>
          </div>

          <label className="label-styles" htmlFor="partDetail">Select an option from below:</label>
          <select name="partDetail" className="form-control" required id="partDetail" onChange={(e) => setSelectedOption(e.target.value)} placeholder="Part Detail">
            <option value="weight" selected>{" "}Enter Part Weight{" "}</option>
            <option value="volume"> Enter Part Volume </option>
          </select>

          <div className="row my-3 mb-3">
            <div className="col-md-6 mb-md-0">
              <div className="form-floating mb-0">
                <input name="partWeight" type="text" className="form-control" required disabled={selectedOption !== "weight"} onChange={handleChange} id="partWeight" placeholder="Enter Part Weight (lbs)" value={partWeight}/>
                <label for="partWeight">Part Weight (lbs)</label>
              </div>
            </div>
            <div className="col-md-6 mb-3 align-self-center">
              <a href="/assets/part-weight-tutorial.pdf" target="_blank" rel="noreferrer">
                <button type="button" className="btn btn-primary w-100 h-100">
                  How to calculate Part Weight
                </button>
              </a>
            </div>
          </div>

          <div className="row my-3 mb-3">
            <div className="col-md-6 mb-md-0">
              <div className="form-floating mb-0">
                <input name="partVolume" type="text" className="form-control" required disabled={selectedOption !== "volume"} onChange={handleChange} id="partVol" placeholder="Part Volume" value={partVolume}/>
                <label for="partVol">Part Volume (Cu. Inch)</label>
              </div>
            </div>
            <div className="col-md-6 mb-3 align-self-center">
              <a href="/assets/part-volume-tutorial.pdf" target="_blank" rel="noreferrer">
                <button type="button" className="btn btn-primary w-100 h-100">
                  How to calculate Part Volume
                </button>
              </a>
            </div>
          </div>

          <div className="row my-3 mb-3">
            <div className="col-md-6 mb-md-0">
              <div className="form-floating mb-0">
                <input name="surfaceArea" type="text" className="form-control" required onChange={handleChange} id="area" placeholder="Surface Area (Sq. Inch)" value={surfaceArea}/>
                <label for="area">Surface Area (Sq. Inch)</label>
              </div>
            </div>
            <div className="col-md-6 mb-3 align-self-center">
              <a href="/assets/surface-area.pdf" target="_blank" rel="noreferrer">
                <button type="button" className="btn btn-primary w-100 h-100">
                  How to calculate Surface Area
                </button>
              </a>
            </div>
          </div>

          <div className="row my-3 mb-3">
            <div className="col-md-6 mb-md-0">
              <div className="form-floating mb-0">
                <input name="partEnvelopeVolume" type="text" className="form-control" required onChange={handleChange} id="enVol" placeholder="Part Envelope Volume" value={partEnvelopeVolume}/>
                <label for="enVol">Part Envelope Volume</label>
              </div>
            </div>
            <div className="col-md-6 mb-3 align-self-center">
              <a href="/assets/part-envelope-volume.pdf" target="_blank" rel="noreferrer">
                <button type="button" className="btn btn-primary w-100 h-100">
                  How to calculate Part Env. Vol.
                </button>
              </a>
            </div>
          </div>

          <div className="row my-3 mt-5">
            <div className="col-md-6 align-self-center">
              <button disabled={false} type="submit" className="btn btn-primary w-100">
                {loading ? <Loading /> : <span>Submit</span>}
              </button>
            </div>
          </div>

        </div>
      </form>
    </div>
  );
};
